// import apiFactory from '~/api/index';
import { ResultEnum } from '~/enums/httpEnum'
import { TokenName, PlaceCookie } from '~/config/cookieKeys'
import { PageEnum } from '~/enums/pageEnum'
import { getProxyByConfig } from '~/config'
import { apiMirror } from "~/utils/api";
import { checkXsollaResponse } from '~/config/xsolla'
import { isBuildWebSite } from '~/config/index'
import { SiteBuilderApi } from '~/api/siteBuild';

// import Message from '~/components/message/index'
// const IMessage = Message.install;
// 批量注册公用组件
const Apis = require.context('~/api/', true, /index.js$/)
const apiRepository = {
}

const NeedAuthorizationHeader = [
  '/api_customer',
  '/v2/store'
]

const buildSiteAuthWhiteList = [
  SiteBuilderApi.publicLang,
  SiteBuilderApi.publicPages,
  SiteBuilderApi.publicLangList,
  SiteBuilderApi.publicInfo,
  SiteBuilderApi.GetMultiLanguages
]

const LoginApis = {
  base: '/login',
  player: '/login/player'
}

const TokenError = [
  ResultEnum.ERROR_SIGNATURE_EXPIRED,
  ResultEnum.ERROR_PERMISSION_DENIED,
  ResultEnum.ERROR_NO_LOGIN,
  ResultEnum.ERROR_API_PERMISSION_DENIED
]

const getUrlByPrefix = (function () {
  const proxy = JSON.parse(process.env.proxy) || []
  return (url) => {
    let backUrl = ''
    for (let i = 0; i < proxy.length; i++) {
      const p = proxy[i]
      const urlPrefix = p[0]
      if (urlPrefix && p[1] && url.indexOf(urlPrefix) === 0) {
        backUrl = p[1]
        break;
      }
    }
    return backUrl
  }
}())
export default function (ctx, inject) {
  const { app, redirect, $utils } = ctx
  const { $axios, $cookies, $sentry } = app
  const BASE_PREFIX = process.env.api_prefix
  $axios.defaults.timeout = 30000
  const token = $cookies.get(TokenName) || ''
  $axios.setToken(token)

  $axios.onRequest((config) => {
    // 根据运行环境，动态修改api 服务proxy
    if ('editing' in config) {
      const editing = config.editing;
      delete config.editing;
      const serverProxy = getProxyByConfig(editing, config.url);
      if (config.url.includes('/merchant') && !config.url.includes(serverProxy)) {
        config.url = config.url.replace('/merchant', serverProxy);
      } else if (config.url.includes('/api_customer') && !config.url.includes(serverProxy)) {
        config.url = config.url.replace('/api_customer', serverProxy);
      } else if (!config.url.includes(serverProxy)) {
        config.url = `${serverProxy}${config.url}`
      }
    }
    // 根据运行环境，动态新增api 服务proxy

    let t = $cookies.get(TokenName)
    const isApiCustomer = NeedAuthorizationHeader.some(part => config.url.includes(part))
    const inBuildSiteWhiteList = buildSiteAuthWhiteList.some(item => config.url.includes(item)) && isBuildWebSite
    if (t) config.headers.Authorization = t
    if (inBuildSiteWhiteList) config.headers.Authorization = PlaceCookie
    if (isApiCustomer) {
      t = $utils.getSiteToken(ctx)
      t && (config.headers.Authorization = t)
    } else if (config.headers.common.Authorization !== t) {
      $axios.setToken(t)
    }
    // 如果是 v2版本的接口，就不需要添加 `/api/v1`前缀了
    config.url = config.url.includes('http') || config.url.includes('api/v2')
      ? config.url
      : (BASE_PREFIX + config.url)

    if (process.server) {
      // 服务端请求的时候需要加上域名
      const proxyPrefix = getUrlByPrefix(config.url)
      if (proxyPrefix) {
        config.url = proxyPrefix + config.url
      }
    }
    if (process.winstonLog) {
      process.winstonLog.info(`request: [${config?.method}] ${config?.url} Request Params: ${JSON.stringify(config.params || config.data)}`)
    }
    return config
  })
  $axios.onResponse(response => {
    // console.log(`response: [${response?.status}] [${response?.config?.method}] ${response?.config?.url} ResponseData: ${JSON.stringify(response.data)}`)
    if (process.winstonLog) {
      process.winstonLog.info(`response: [${response?.status}] [${response?.config?.method}] ${response?.config?.url} ResponseData: ${JSON.stringify(response.data)}`)
    }

    // blob 文件
    if (response.status === 201 || response.config.responseType === 'blob') {
      return response
    }
    if (/^[4|5]/.test(response.status)) {
      return Promise.reject(response.statusText)
    }

    const resData = response.data

    const { code, data, message } = resData
    // token出现认证问题
    if (TokenError.includes(code)) {
      const isApiCustomer = NeedAuthorizationHeader.some(part => response?.config?.url.includes(part))
      const isGetCustomerInfo = response?.config?.url.includes(SiteBuilderApi.GetUserInfo)
      console.log(`BASE_401 axios: ${response?.config?.url}, ${code}, isBuildWebSite: ${isBuildWebSite}, isApiCustomer: ${isApiCustomer}`)
      $utils.siteUserLoginOut(ctx)

      // 商城中 isApiCustomer接口认证失败跳转 forbidden页面，其它跳转 401
      if (!isGetCustomerInfo) {
        redirect(isApiCustomer
          ? PageEnum.FORBIDDEN_PAGE
          : PageEnum.BASE_401, {
          c: code || 0,
          u: response?.config?.url || '',
          h: location?.href
        })
      }
      return Promise.reject(resData)
    }

    const authorization = response.headers?.authorization;
    //  获取token
    if (response.config.url.indexOf(LoginApis.player) > 0) {
      authorization && (data.token = authorization)
    } else if (response.config.url.indexOf(LoginApis.base) > 0) {
      authorization && (data.token = authorization)
    }


    // 这里逻辑可以根据项目进行修改
    const hasSuccess = code === ResultEnum.INFO_SUCCESS;
    if (hasSuccess) {
      return data;
    }

    if (checkXsollaResponse(response)) {
      return response
    }
    // // 在此处根据自己项目的实际情况对不同的code执行不同的操作
    // // 如果不希望中断当前请求，请return数据，否则直接抛出异常即可
    // let timeoutMsg = '';
    // switch (code) {
    //   case ResultEnum.NOTFINISHUSERCODE:
    //     return data;
    //   case ResultEnum.ERROR_SIGNATURE_EXPIRED:
    //      // $utils.loginOut(ctx);
    //     break;
    //
    //   default:
    //     timeoutMsg = message
    // }

    // if (code === ResultEnum.ERROR_VALIDATION) {
    //   return Promise.reject(message)
    // }

    const errorMessageMode = response.config?.errorMessageMode
    const $store = ctx.store || app.$store
    $store.commit('error/UPDATE_ERROR', { code, message, mode: errorMessageMode, })
    return Promise.reject(message);
    // return response.data
  })
  $axios.onError(err => {
    $sentry.captureException(err)
    console.log(`error: [${err.code}] | [${err?.message}] | ${err?.data}`)

    if (process.winstonLog) {
      process.winstonLog.error(
        `error: [${err.code}] | [${err?.message}] | ${err?.data}`
      );
    }
    return Promise.reject(err)
  })
  $axios.onResponseError(err => {
    // eslint-disable-next-line no-console
    console.log('response Error: ', err?.message || 'response Error')
    if (process.winstonLog) {
      process.winstonLog.error('response Error: ', err?.message || 'response Error');
    }
    const $store = ctx.store || app.$store
    $store.commit('error/UPDATE_ERROR', { message: err && err.message || "unknown error！", mode: 'message', })
    return Promise.reject(err)
  })


  const requestWithAxios = $axios; // apiFactory($axios);
  // if(Object.keys(apiRepository).length  === 0){ // 会执行两次，不知道为啥
  Apis.keys().forEach(path => {
    // const fileName = path.replace(/(.*\/)*([^.]+).*/ig, "$2"); // 获取组件文件名
    const apiName = path.replace(/./, '').replace(/(.*\/)*([^.]+).*/ig, "$1").replace(/\/([^/]*)\//g, '$1')
    const api = Apis(path)?.default
    if (!apiName.includes('/') && api) {
      apiRepository[apiName] = api(requestWithAxios)
      apiMirror.setApi(apiName, api(requestWithAxios))
    }
  })
  // }
  inject('api', apiRepository);
}


