export const SiteEnum = {
  BASE_PAGE: 'home',
  BASE_PAGE_ID: 0
}

export const SiteColumnsNameEnum = {
  PAGE: '页面',
  LOGIN: '登陆',
  SEO: 'SEO',
  THEME: '主题',
  ASSETS: '素材库',
  LANGUAGE: '语言',
  SETTING: '配置',
  GLOBAL: '全局'
}

export const PageSetting = {
  getMenuInfo() {
    return {
      icon: 'pageSvg',
      name: SiteColumnsNameEnum.PAGE
    }
  }
}

export const LoginSetting = {
  children: [
    //
  ],
  getMenuInfo() {
    return {
      icon: 'icon-login-nor',
      name: SiteColumnsNameEnum.LOGIN
    }
  }
}

export const GlobalSetting = {
  children: [
    {
      name: SiteColumnsNameEnum.ASSETS
    },
    {
      name: SiteColumnsNameEnum.GLOBAL
    },
    {
      name: SiteColumnsNameEnum.LANGUAGE
    },
    {
      name: SiteColumnsNameEnum.SEO
    }
  ],
  getMenuInfo() {
    return {
      icon: 'iconGlobal',
      name: SiteColumnsNameEnum.GLOBAL
    }
  }
}

export const ColumnsSetting = [
  PageSetting,
  LoginSetting,
  GlobalSetting
]
