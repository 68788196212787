const Api =  {
  // VirtualGroups: '/merchant/store/virtual-item-groups',
  VirtualGroups: '/api/v2/merchant/virtual-group/get',
}

export default (request) => {
  return {
    //  虚拟物品分组列表-查询
    getVirtualGroups: (params, options) => {
      return request.get(Api.VirtualGroups, { params, ...options })
    }
  }
}
