/*
 ** 只在生产模式的客户端中使用
 */

 // import {Severity} from "@sentry/types/dist/severity";

import {isBuildWebSite} from '~/config/index'
const canLogger = !!isBuildWebSite

 class Logger {
   constructor(instance, user) {
     if(!instance){
       // eslint-disable-next-line no-console
       console.error('监控实例不能为空')
     }
     this.log = instance
     this.user = user
   }

   captureMessage(message,...rest){
     if(!message){
       // eslint-disable-next-line no-console
       console.warn('请传入message')
       return
     }
     const email = this.user?.email || ''
     const sendMessage = email? `【${email}】${message}`: message
     // eslint-disable-next-line no-console
     canLogger ? this.log.captureMessage(sendMessage, ...rest): console.log(message,this.user)
   }

   /*
   * 捕获支付信息
   * */
   capturePayMessage(message){
     this.captureMessage(    '【pay】' + message)
   }

   setUser(user){
     this.log.setUser(user)
     this.user = user
   }

 }

export default ({ app: { router, $sentry }, store },inject) => {

  inject('logger', new Logger($sentry, store?.user?.siteLoginUserInfo))
}
