import { DeviceEnum } from '~/enums/deviceEnum'

const getters = {
  menuKeys: state => state.menu.menuKeys,
  recentColors: state => state.theme.recentColors,
  isMobile: state => state.editor.device === DeviceEnum.MOBILE,
  isDesktop: state => state.editor.device === DeviceEnum.DESKTOP,
  shopCart(state) {
    return state.goods.shopCartList.reduce((total, item) => {
      total[item.goods_id] = item.goods_num
      return total
    }, {})
  },
  shopCartNumber(state) {
    return state.goods.shopCartList.reduce((total, item) => {
      total = total + item.goods_num
      return total
    }, 0)
  }
}

export default getters
