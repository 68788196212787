export const state = () => ({
  info: {},
  projectId: '',
  merchantId: '',
  merchantInfo: {},
  domain: '',
  siteInfo: {},
  currency: [],
  currencieMap: {},
})

export const mutations = {
  SET_INFO(state, info) {
    if(!info){
      return
    }
    state.info = info
    state.projectId = info.project_id || ''
    state.merchantId = info.merchant_id || ''
    state.domain = info.domain || ''
  },
  SET_MERCHANTINFO(state, info) {
    if(!info) {
      return
    }
    state.merchantInfo = info
  },
  UPDATE_SITEINFO(state, payload) {
    state.siteInfo = payload
  },
  SET_CURRENCYINFO (state, params) {
    state.currency = params.currency;
    state.currencieMap = params.currencieMap;
  } 
}
export const actions ={
}
