import moment from 'moment'
import {getAlpha, setAlpha} from '~/utils/colors'
import {backgroundColorConst} from "~/site/model/editConfig";
import {BackgroundSizeEnum, DefaultBackgroundSize} from '~/enums/DomEnum'


const special = ['logoBackgroundImage', 'logoBackgroundColor', 'logoBackgroundSize', 'background']

function setBackgroundImage(backgroundImage, setKey) {
  if (special.includes(setKey)) {
    this[setKey] = backgroundImage
  } else {
    this.backgroundImage = backgroundImage || ''
  }
  if (this.backgroundColor) {
    const a = getAlpha(this.backgroundColor)
    if (a >= 0.7) { // 设置背景的时候需要将背景色替换透明度为 0，方便看效果
      if (this.setBackgroundColor && typeof this.setBackgroundColor === 'function') {
        const rgbaColor = setAlpha(this.backgroundColor, backgroundColorConst[1])
        this.setBackgroundColor(rgbaColor)
      }
    }
  }

  // if (this.backgroundOpacity > 0.7) {
  //   this.backgroundOpacity = 0.7
  // }
  // setBackgroundImage.prototype.bind(model)
  if (this.backgroundGradient && typeof this.setBackgroundGradient === 'function') {
    this.setBackgroundGradient('')
  }
  if (this.setBackgroundImageCb  && typeof this.setBackgroundGradient === 'function') {
    this.setBackgroundImageCb()
  }
}

function setBackgroundColor(color, setKey) {
  if (special.includes(setKey)) {
    this[setKey] = color || ''
  } else {
    this.backgroundColor = color || ''
  }
  if (this.setBackgroundColorCb && typeof this.setBackgroundColorCb === 'function') {
    this.setBackgroundColorCb()
  }
}
function setBackgroundSize(size, setKey) {
  if (special.includes(setKey)) {
    this[setKey] = size || ''
  } else {
    this.backgroundSize = size || ''
  }
  if (this.setBackgroundSizeCb && typeof this.setBackgroundSizeCb === 'function') {
    this.setBackgroundSizeCb()
  }
}
function setBackgroundBlur(size) {
  this.backgroundBlur = size || 0
}
function setShowBackgroundColor(value) {
  this.backgroundColorShow = !!value
}
function setShowBackgroundImage(value) {
  this.backgroundImageShow = !!value
}

/*
*  提供方法修改 背景色和背景图
*
* */
export const SetModelBackgroundMethod = (model)=>{
  if(!model){
    // eslint-disable-next-line
    return  console.warn('传入model')
  }
  // 做一下兼容
  if(model && Object.prototype.hasOwnProperty.call(model,'backGroundColor')){
    model.backgroundColor = model.backGroundColor
    delete model.backGroundColor
  }
  if(model && Object.prototype.hasOwnProperty.call(model,'backGroundUrl')){
    model.backgroundImage = model.backGroundUrl
    delete model.backGroundUrl
  }
  model.setBackgroundImage = setBackgroundImage
  // setBackgroundColor.prototype.bind(model)
  model.setBackgroundColor = setBackgroundColor
  // setBackgroundSize.prototype.bind(model)
   model.setBackgroundSize = setBackgroundSize
  return model
}

export const initBackgroundKeyByModel= (model, options, showBackgroundImage = true,showBackgroundColor = true, showBackgroundBlur = true)=>{
  if(!model){
    return null
  }
  if(showBackgroundColor){
    if(options && Object.prototype.hasOwnProperty.call(options,'backgroundColorShow')){
      model.backgroundColorShow = options.backgroundColorShow
    }else{
      model.backgroundColorShow = true
    }
    model.backgroundColor = options?.backgroundColor ||  model.backgroundColor || ''
    model.setBackgroundColor = setBackgroundColor
    model.setShowBackgroundColor = setShowBackgroundColor
    // setBackgroundColor.prototype.bind(model)
    // setShowBackgroundColor.prototype.bind(model)
  }
  if(showBackgroundImage){
    if(options && Object.prototype.hasOwnProperty.call(options,'backgroundImageShow')){
      model.backgroundImageShow = options.backgroundImageShow
    }else{
      model.backgroundImageShow = true
    }
    model.backgroundImage = options?.backgroundImage || model.backgroundImage || ''

    model.backgroundSize = options?.backgroundSize || DefaultBackgroundSize
    // 透明度可能为 0
    model.backgroundOpacity = !options?.backgroundOpacity && options?.backgroundOpacity !== 0
      ? 1
      : options?.backgroundOpacity
    model.setBackgroundImage = setBackgroundImage
    model.setBackgroundSize = setBackgroundSize
    // setBackgroundSize.prototype.bind(model)
    // setShowBackgroundImage.prototype.bind(model)
    // setBackgroundImage.prototype.bind(model)
    model.setShowBackgroundImage = setShowBackgroundImage
  }
  if(showBackgroundBlur){
    if(options && Object.prototype.hasOwnProperty.call(options,'backgroundBlur')){
      model.backgroundBlur = options.backgroundBlur
    }else{
      model.backgroundBlur =   model.backgroundBlur || 0
    }
    model.setBackgroundBlur = setBackgroundBlur
  }
  SetModelBackgroundMethod(model)
}

/*
* 只设置背景色
*
* */
export const initOnlyBackgroundImageByModel= (model, options)=>{
  initBackgroundKeyByModel(model, options, true, false, true)
}

export const initBackgroundByModel = (model, options) => {
  initBackgroundKeyByModel(model, options, true, true, true)
}

export const initOnlyBackgroundColorByModel = (model, options) => {
  initBackgroundKeyByModel(model, options, false, true, true)
}


export  const isShowColor = (bgModel) =>{
  let hasBackgroundColorShow
  let isShow = true
  const bg = bgModel
  if (!bg) {
    return false
  }
  if (Object.prototype.hasOwnProperty.call(bg, 'backgroundColorShow')) {
    if (bg.backgroundColorShow !== undefined) {
      isShow = bg.backgroundColorShow
      hasBackgroundColorShow = true;
    }
  }
  // 兼容头部开始展示的逻辑
  if (!hasBackgroundColorShow && Object.prototype.hasOwnProperty.call(bg, 'value')) {
    if (bg.value !== undefined) {
      isShow = bg.value
    }
  }
  return isShow
}
export const isShowBackgroundImage = (bgModel) =>{
  let hasBackgroundImageShow
  let isShow = true
  const bg = bgModel
  if (!bg) {
    return false
  }
  if (Object.prototype.hasOwnProperty.call(bg, 'backgroundImageShow')) {
    if (bg.backgroundImageShow !== undefined) {
      isShow = bg.backgroundImageShow
      hasBackgroundImageShow= true
    }
  }
  // 兼容头部开始展示的逻辑
  if (!hasBackgroundImageShow && Object.prototype.hasOwnProperty.call(bg, 'value')) {
    if (bg.value !== undefined) {
      isShow = bg.value
    }
  }
  return isShow
}

export const isShowBackgroundImageBlur = (bgModel) => {
  let isShow = false;
  if(isShowBackgroundImage(bgModel)) {
    if(bgModel.backgroundBlur) {
      isShow = true;
    }
  }
  return isShow;
}

export const computeBackgroundStyle = (model, customStyle) =>{
  const bg = model
  if (!bg) {
    return {}
  }
  const bgImageUrl = bg.backgroundImage;
  const backgroundBlur = bg.backgroundBlur
  const blurStyle = {
    filter: backgroundBlur? `blur(${backgroundBlur}px)`: ''
  }
  let bgStyle = {}
  if (bgImageUrl) {
    const bgSize = bg.backgroundSize || DefaultBackgroundSize
    bgStyle = {
      backgroundImage: bgImageUrl ? `url(${bgImageUrl})` : 'none' ,
      opacity: bg.backgroundOpacity,
      backgroundRepeat: 'no-repeat',
    }
    if (bgSize === BackgroundSizeEnum.STRETCH) {
      Object.assign(bgStyle,{
        backgroundSize: bgSize,
      })

    } else if(bgSize === BackgroundSizeEnum.REPEAT) {
      Object.assign(bgStyle,{
        backgroundRepeat: 'repeat',
      })
    }else {
      Object.assign(bgStyle,{
        backgroundSize: bgSize,
        backgroundPosition: '50%',
      })
    }
  } else {
    bgStyle = {
      backgroundImage: bgImageUrl?`url(${bgImageUrl})` : 'none',
      backgroundSize: bg.backgroundSize || DefaultBackgroundSize,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50%',
      opacity: bg.backgroundOpacity,
    }
  }
  return isShowBackgroundImage(bg) ? { ...customStyle, ...bgStyle ,...blurStyle } : customStyle || blurStyle
}

export const generatePreviewUrl = (model, expiredMinute) => {
  const origin = window.location.origin
  let url = `${origin}/preview/${model.baseInfo.siteId}/${model.lang}`
  if (expiredMinute) {
    const time = moment().add(expiredMinute, 'm').format('YYYY-MM-DD hh:mm:ss') + '&&' + model.baseInfo.siteId
    url+= `?timestamp=${btoa(time)}`
  }
  return url
  // return `btoa(time)`
}

export const checkQrcodeUrlExpired = (query, params) => {
  let result = true
  const timestamp = query?.timestamp
  const { sid } = params
  if (!timestamp || !sid) {
    result = false
  } else {
    try {
      // eslint-disable-next-line new-cap
      const data = new Buffer.from(timestamp, 'base64').toString('binary')
      const [expired, domain] = data.split('&&')
      if (domain !== sid || !expired) {
        result = false
      }
    } catch (e) {
      result = false
    }
  }
  return result
}
