const Api = {
  StoreActivityItems: '/api/v2/store/sale/biz/add/credit/details',
  MerchantActivityItems: '/api/v2/merchant/sale/biz/add/credit/details',
  PickActivityItem: '/api/v2/store/order/gift/create',
}

export default (request) => {
  return {
    //  活动物品列表-查询
    getActivityItems: (params, options) => {
      const { editing } = options
      // params && (params.enabled = 1)
      return request.get(
        editing ? Api.MerchantActivityItems : Api.StoreActivityItems,
        { params, ...options }
      )
    },
    //  领取活动物品
    pickActivityItems: (params) => {
      return request.post(Api.PickActivityItem, params)
    },
  }
}
