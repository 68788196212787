import dom from './dom'
const getProxyByConfig = (isEdit, url) => {
  // isEdit = false 表示非设计模式，用api_customer，其余情况用merchant
  return url.includes('api/v2') ? '' : isEdit === false ? '/api_customer' : '/merchant'
}

const xss = dom.DOMPurifyConfig
/*
 *  是否是建站器建出来的网站，通过process.env.SITE_ID 这个变量控制
 *  默认是是一段特殊字符 SITE_ID_PLACEHOLDER  ，线上更换后是一个数字ID需要通过弱等判断比价方便
 *  是的话需要把一些接口替换成 api_customer
 * */
// eslint-disable-next-line eqeqeq
const isBuildWebSite = !!process.env.IS_BUILD_SITE
const GET_SITE_ID = () => process.env.SITE_ID
// eslint-disable-next-line
// console.log(
//   isBuildWebSite,
//   GET_SITE_ID(),
//   process.env.IS_BUILD_SITE,
//   'VERSION:' + process.env.BUILD_VERSION
// )
const isProduction = process.env.NODE_ENV === 'production'
/* 是否是线上环境 */
const isRelease = process.env.RUN_ENV === 'release'
/* 是否是与预发布环境 */
const isPre = process.env.RUN_ENV === 'pre'
// 建站器部分接口前缀替换
const siteBuilderPrefix = isBuildWebSite ? '/merchant' : '/api_customer'

// 商城默认货币国家
const default_country = 'US'
// 商城默认货币
const default_currency = 'USD'
// 商品默认语言
const default_good_lang = 'en'
// 建站器默认语言
const default_site_lang = 'en'

// 调试支付页面
const debugPayPath = '/demo/pay'

// 支付页面地址
const PAY_URL = {
  dev: 'http://127.0.0.1:3501',
  test: 'https://secure.test.kopglobal.com',
  pre: 'https://preview-secure.kopglobal.com',
  release: 'https://secure.kopglobal.com'
}

export {
  debugPayPath,
  getProxyByConfig,
  xss,
  isBuildWebSite,
  siteBuilderPrefix,
  isProduction,
  isRelease,
  isPre,
  GET_SITE_ID,
  default_country,
  default_currency,
  default_good_lang,
  default_site_lang,
  PAY_URL,
}
